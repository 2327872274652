import React from "react";
import { Center, Spinner, VStack } from "@chakra-ui/react";

export const LoadingPage: React.FC = () => {
  return (
    <Center height="700px">
      <VStack direction="row">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </VStack>
    </Center>
  );
};
