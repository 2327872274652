import React, { ReactNode } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Stack,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import {
  MdBrightness7,
  MdMenu,
  MdArrowDropDown,
  MdBrightness4,
  MdImage,
  MdEvent,
  MdAssignment,
  MdVideoLabel
} from "react-icons/md";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { Link as ReachLink } from "react-router-dom";
import logo from "../assets/logo.png";
import context from "../contexts/auth";

interface LinkItemProps {
  name: string;
  icon: IconType;
  link: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Eventos", icon: MdEvent, link: "/admin/eventos" },
  { name: "Noticias", icon: MdAssignment, link: "/admin/noticias" },
  { name: "Slides", icon: MdImage, link: "/admin/slides" },
  { name: "Videos", icon: MdVideoLabel, link: "/admin/videos" }
];

export default function BodyLayout({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav openMobile={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bgGradient={[
        "linear(to-tr, gray.400,gray.600)",
        "linear(to-t, gray.500,gray.700)",
        "linear(to-b, gray.700,gray.900)",
      ]}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image
          boxSize="150px"
          width="auto"
          src={logo}
          alt="logo cherry"
          marginTop={20}
        />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Stack spacing={3} marginBottom={2} marginTop={12}>
        <Text
          fontSize="2xl"
          align={"center"}
          color={"whiteAlpha.600"}
          marginTop={2}
        >
          Admin | Turismo
        </Text>
      </Stack>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          color={"whiteAlpha.600"}
          link={link.link}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  link: string;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Link as={ReachLink} to={rest.link} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "blue.600",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  openMobile: () => void;
}

const MobileNav = ({ openMobile, ...rest }: MobileProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logout, user } = React.useContext(context);
  const { colorMode, toggleColorMode } = useColorMode();

  const handleLogout = () => {
    onOpen();
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("whiteAlpha.900", "gray.800")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      shadow={"md"}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={openMobile}
        variant="outline"
        aria-label="open menu"
        icon={<MdMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Tacaratu
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={colorMode === "light" ? <MdBrightness4 /> : <MdBrightness7 />}
          onClick={toggleColorMode}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={
                    "https://th.bing.com/th/id/OIP.zsaaVp0tIiSnOK-1rYpBnwAAAA?pid=ImgDet&rs=1"
                  }
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{user?.name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {user?.type}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <MdArrowDropDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
      <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Logout</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>Realmente deseja sair?</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={logout}>
              Sair
            </Button>
            <Button onClick={onClose}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
