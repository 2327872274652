import React from "react";
import {
  FormControl,
  Stack,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  useToast,
  SimpleGrid,
  Textarea,
  InputGroup,
  InputLeftElement,
  Progress,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { MdCheck, MdFileUpload } from "react-icons/md";
import { api } from "../../../services/api";
import { AxiosError, AxiosResponse } from "axios";
import context from "../../../contexts/auth";
import { useHistory } from "react-router";

interface NewsForm {
  description: string;
  title: string;
  tumbnail: string;
  intro: string;
  id: any;
}

export interface Param {
  id: number;
}

const validationSchema = Yup.object({
  title: Yup.string().required("Título não pode ser vazio.").max(300, "Tamanho máximo do texto é de 300 caracteres."),
  description: Yup.string().required("Descrição não pode ser vazia."),
  intro: Yup.string().required("Introdução não pode ser vazia.").max(300, "Tamanho máximo do texto é de 300 caracteres."),
  tumbnail: Yup.mixed().test(
    "type",
    "Formatos aceitos: .jpeg, .jpg e .png",
    (value) => {
      return (
        (value &&
          (value.type === "image/jpeg" || value.type === "image/png")) ||
        value === undefined
      );
    }
  ),
});

export const FormAlterarNoticias: React.FC<Param> = (props: Param) => {
  const { refresh, logout } = React.useContext(context);
  const [initialValues, setInitialValues] = React.useState<NewsForm>({
    description: "",
    title: "",
    intro: "",
    tumbnail: "",
    id: props.id,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const toast = useToast();
  let history = useHistory();

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await api
      .get(`news/detail/${props.id}`)
      .then((res: AxiosResponse) => {
        setInitialValues({
          description: res.data[0].description,
          title: res.data[0].title,
          intro: res.data[0].intro,
          tumbnail: "",
          id: props.id,
        });
        setLoadingData(false);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          refresh().catch((error: AxiosError) => {
            if (error.response?.status === 401) {
              logout();
            }
          });
        }
      });
  };

  const updateNews = async (data: FormData) => {
    await api
      .post("news/update", data)
      .then((res: AxiosResponse) => {
        console.log(res.data);
        toast({
          title: "Informação.",
          description: "Noticia alterada com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setLoading(false);
        history.replace("/admin/noticias");
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          refresh().catch((error: AxiosError) => {
            if (error.response?.status === 401) {
              logout();
            }
          });
        } else if (
          error.response?.status === 500 ||
          error.response?.status === 400
        ) {
          toast({
            title: "Informação.",
            description:
              "Ops! Ocorreu um error, por favor entre em contato com o administrador.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        setLoading(false);
      });
  };

  return (
    <Stack spacing={4}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          let data = new FormData();

          if (values.tumbnail !== "" || values.tumbnail !== null) {
            data.append("tumbnail", values.tumbnail);
          }
          data.append("title", values.title);
          data.append("intro", values.intro);
          data.append("description", values.description);
          data.append("id", values.id);

          setLoading(true);
          updateNews(data);
          resetForm();
        }}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Progress
                size="xs"
                isIndeterminate={loadingData}
                hidden={!loadingData}
              />
              <Field name="title">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.title && touched.title}
                    isRequired
                  >
                    <FormLabel htmlFor="title">Título:</FormLabel>
                    <Input
                      {...field}
                      id="title"
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.title && touched.title && errors.title}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="intro">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.intro && touched.intro}
                    isRequired
                  >
                    <FormLabel htmlFor="intro">Introdução:</FormLabel>
                    <Textarea
                      {...field}
                      id="intro"
                      type="text"
                      value={values.intro}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.intro && touched.intro && errors.intro}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.description && touched.description}
                    isRequired
                  >
                    <FormLabel htmlFor="description">Descrição:</FormLabel>
                    <Textarea
                      {...field}
                      id="description"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <SimpleGrid columns={2} spacing={10}>
                <Field name="tumbnail">
                  {(field: any) => (
                    <FormControl
                      isInvalid={!!errors.tumbnail && touched.tumbnail}
                    >
                      <FormLabel htmlFor="tumbnail">Tumbnail:</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<MdFileUpload />}
                        />
                        <Input
                          {...field}
                          id="tumbnail"
                          type="file"
                          name="tumbnail"
                          multiple
                          onChange={(event: any) => {
                            field.form.setFieldValue(
                              "tumbnail",
                              event.currentTarget.files[0]
                            );
                          }}
                          onBlur={handleBlur}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.tumbnail && touched.tumbnail && errors.tumbnail}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>
              <Stack spacing={10} width="150px">
                <Button
                  type="submit"
                  leftIcon={<MdCheck />}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  spinnerPlacement="start"
                  isLoading={loading}
                  loadingText="Alterando..."
                  disabled={loadingData}
                >
                  Alterar
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
