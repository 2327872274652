import React from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { Eventos } from "../pages/eventos";
import { Noticias } from "../pages/noticias";
// import { Error404 } from "../pages/error/404";
import { CadastrarEventos } from "../pages/eventos/cadastrar";
import { AlterarEventos } from "../pages/eventos/alterar";
import { PublicarNoticia } from "../pages/noticias/publicar";
import { AlterarNoticias } from "../pages/noticias/alterar";
import { Slides } from "../pages/slides";
import { Videos } from "../pages/videos";
import { AlterarVideos } from "../pages/videos/alterar";
import { CadastrarVideos } from "../pages/videos/cadastrar";

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
        <Route exact path={["/admin/eventos", "/"]} component={Eventos} />
        <Route exact path="/admin/eventos/novo" component={CadastrarEventos} />
        <Route exact path="/admin/eventos/alterar/:id" component={AlterarEventos} />

        <Route exact path="/admin/noticias" component={Noticias} />
        <Route exact path="/admin/noticias/publicar" component={PublicarNoticia} />
        <Route exact path="/admin/noticias/alterar/:id" component={AlterarNoticias} />

        <Route exact path="/admin/slides" component={Slides} />

        <Route exact path={["/admin/videos", "/"]} component={Videos} />
        <Route exact path="/admin/videos/novo" component={CadastrarVideos} />
        <Route exact path="/admin/videos/alterar/:id" component={AlterarVideos} />

        {/* <Route component={Error404} /> */}
    </BrowserRouter>
  );
};

export default AppRoutes;
