import React, { useContext } from "react";

import context from "../contexts/auth";
import AuthRoutes from "./auth.routes";
import AppRoutes from "./app.routes";

const Routes: React.FC<{}> = () => {
  const { signed } = useContext(context);

  return signed ? <AppRoutes /> : <AuthRoutes />;
};

export default Routes;
