import React from "react";
import { Box, Stack, useColorModeValue, Flex } from "@chakra-ui/react";

import BodyLayout from "../../components/BodyLayout";
import { BaseBread } from "../../components/Breadcrumb/BaseBread";
import {
  FormAlterarEventos
} from "../../components/Forms/Eventos/FormAlterar";
import { useParams } from "react-router";

const itens = [
  {
    link: "/admin/eventos",
    title: "Eventos",
    isCurrent: true,
  },
  {
    link: "#",
    title: "Alterar Evento",
    isCurrent: true,
  },
];

export const AlterarEventos: React.FC<{}> = () => {
  let { id } = useParams<any>();

  return (
    <BodyLayout>
      <Flex marginTop={5} marginBottom={5}>
        <Stack>
          <BaseBread itens={itens} />
        </Stack>
      </Flex>
      <Box
        rounded={"lg"}
        boxShadow={"lg"}
        p={10}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={6}>
          <FormAlterarEventos
            id={id}
          />
        </Stack>
      </Box>
    </BodyLayout>
  );
};
