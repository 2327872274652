import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export interface Item {
  link: string;
  isCurrent: boolean;
  title: string;
}
interface Props {
  itens: Array<Item>;
}

export const BaseBread: React.FC<Props> = (props) => {
  return (
    <Breadcrumb marginBottom={5}>
      {props.itens.map((item, index) => (
        <BreadcrumbItem isCurrentPage={item.isCurrent} key={index}>
          <BreadcrumbLink as={Link} to={item.link}>
            {item.title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
