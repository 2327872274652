import React from "react";
import {
  FormControl,
  Stack,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  useToast,
  SimpleGrid,
  Textarea,
  Progress,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { MdCheck } from "react-icons/md";
import { api } from "../../../services/api";
import { AxiosError, AxiosResponse } from "axios";
import context from "../../../contexts/auth";
import { useHistory } from "react-router";

export interface VideoForm {
  title: string;
  video: string;
  id: number;
}

export interface Param {
  id: number;
}

const validationSchema = Yup.object({
  title: Yup.string().required("Título não pode ser vazio.").max(300, "Tamanho máximo do texto é de 300 caracteres."),
  video: Yup.string().required("Video não pode ser vazia.").max(300, "Tamanho máximo do texto é de 300 caracteres."),
});

export const FormAlterarVideo: React.FC<Param> = (props: Param) => {
  const { refresh, logout } = React.useContext(context);
  const [initialValues, setInitialValues] = React.useState<VideoForm>({
    title: "",
    video: "",
    id: 0,
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [submint, setSubmit] = React.useState<boolean>(false);

  const toast = useToast();
  let history = useHistory();

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await api
      .get(`videos/detail/${props.id}`)
      .then((res: AxiosResponse) => {
        setInitialValues({
          title: res.data[0].title,
          video: res.data[0].video,
          id: res.data[0].id,
        });
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        refresh().catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            logout();
          }
        });
      });
  };

  return (
    <Stack spacing={4}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setSubmit(true);
          api
            .put("videos/update", {
              title: values.title,
              video: values.video,
              id: initialValues.id,
            })
            .then(() => {
              toast({
                title: "Informação.",
                description: "Video alterado com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });

              resetForm();
              setSubmit(false);
              history.replace("/admin/videos");
            })
            .catch((error: AxiosError) => {
              if (error.response?.status === 401) {
                refresh().catch((error: AxiosError) => {
                  if (error.response?.status === 401) {
                    logout();
                  }
                });
              } else if (
                error.response?.status === 500 ||
                error.response?.status === 400 ||
                error.response?.status === 404
              ) {
                toast({
                  title: "Informação.",
                  description:
                    "Ops! Ocorreu um error, por favor entre em contato com o administrador.",
                  status: "error",
                  duration: 8000,
                  isClosable: true,
                });
              }
            });
        }}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Progress size="xs" isIndeterminate={loading} hidden={!loading} />
              <Field name="title">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.title && touched.title}
                    isRequired
                  >
                    <FormLabel htmlFor="title">Título:</FormLabel>
                    <Input
                      {...field}
                      id="title"
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.title && touched.title && errors.title}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="video">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.video && touched.video}
                    isRequired
                  >
                    <FormLabel htmlFor="title">Video:</FormLabel>
                    <Input
                      {...field}
                      id="video"
                      type="text"
                      value={values.video}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.video && touched.video && errors.video}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Stack spacing={10} width="150px">
                <Button
                  type="submit"
                  isLoading={submint}
                  loadingText={"Alterando..."}
                  leftIcon={<MdCheck />}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  disabled={loading}
                >
                  Alterar
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
