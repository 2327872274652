import React from "react";
import {
  Flex,
  Box,
  FormControl,
  Stack,
  Heading,
  useColorModeValue,
  Image,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { MdLock } from "react-icons/md";
import { Crendenciais } from "../../components/Credenciais";

import logo from "../../assets/logo.png";
import context from "../../contexts/auth";
import { AxiosError } from "axios";
import ReCAPTCHA from "react-google-recaptcha";

interface AuthForm {
  user: string;
  password: string;
  recaptcha: string;
}

const validationSchema = Yup.object({
  user: Yup.string().required("Usuário não pode ser vazio."),
  password: Yup.string()
    .required("Senha não pode ser vazia.")
    .min(8, "Senha com no minimo 8 caracteres."),
});

export const Auth: React.FC<{}> = () => {
  const initialValues: AuthForm = { user: "", password: "", recaptcha: "" };
  const [isLoading, setIsLoading] = React.useState(false);
  const captcha = React.createRef<ReCAPTCHA>();

  const toast = useToast();
  const { auth, handleLoading } = React.useContext(context);

  const getToken = () => {captcha.current?.execute();}

  React.useEffect(() => {
    getToken();
  }, [])

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={1} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Image marginTop="-100" boxSize="350px" src={logo} alt="logo cherry" width="500px" />
          <Heading fontSize={"4xl"}>Informe suas credências</Heading>
          <Heading as="h3" size="md" isTruncated>
            TURISMO TACARATU
          </Heading>
        </Stack>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setIsLoading(true);

            await auth({
              username: values.user,
              password: values.password,
              recaptcha: values.recaptcha,
            }).catch((error: AxiosError) => {
              if (error.response?.status === 401) {
                toast({
                  title: "Autenticação.",
                  description: "Credênciais inválidas.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
                handleLoading(false);
              } else if (
                error.response?.status === 400 ||
                error.response?.status === 404 ||
                error.response?.status === 500
              ) {
                toast({
                  title: "Aviso.",
                  description: "Ops! Ocorreu um error, por favor entre em contato com o administrador.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            });

            setIsLoading(false);
          }}
        >
          {({
            handleSubmit,
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box rounded={"lg"} boxShadow={"lg"} p={8}>
                <Stack spacing={4}>
                  <Field name="user">
                    {(field: any) => (
                      <FormControl
                        isInvalid={!!errors.user && touched.user}
                        isRequired
                      >
                        <FormLabel htmlFor="user">Usuário:</FormLabel>
                        <Input
                          {...field}
                          id="user"
                          type="text"
                          value={values.user}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <FormErrorMessage>
                          {errors.user && touched.user && errors.user}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password">
                    {(field: any) => (
                      <FormControl
                        isInvalid={!!errors.password && touched.password}
                        isRequired
                      >
                        <FormLabel htmlFor="password">Senha:</FormLabel>
                        <Input
                          {...field}
                          id="password"
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <FormErrorMessage>
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="recaptcha">
                    {(field: any) => (
                      <ReCAPTCHA
                        sitekey="6LedW6oaAAAAAI1sURukdqJ8TqvvRp_iv0BTm1Dq"
                        onChange={(value: any) => {
                          getToken();
                          field.form.setFieldValue("recaptcha", value);
                        }}
                        ref={captcha}
                        size="invisible"
                      />
                    )}
                  </Field>
                  <Stack spacing={10}>
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      leftIcon={<MdLock />}
                      bg={"blue.400"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                    >
                      Entrar
                    </Button>
                  </Stack>
                </Stack>
                <Crendenciais />
              </Box>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};
