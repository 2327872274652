import React from "react";
import { Box, Stack, useColorModeValue, Flex } from "@chakra-ui/react";

import BodyLayout from "../../components/BodyLayout";
import { BaseBread } from "../../components/Breadcrumb/BaseBread";
import { useParams } from "react-router";
import { FormAlterarNoticias } from "../../components/Forms/Noticias/FormAlterar";

const itens = [
  {
    link: "/admin/eventos",
    title: "Eventos",
    isCurrent: true,
  },
  {
    link: "/admin/noticias",
    title: "Noticias",
    isCurrent: true,
  },
  {
    link: "#",
    title: "Alterar Noticia",
    isCurrent: true,
  },
];

export const AlterarNoticias: React.FC<{}> = () => {
  let { id } = useParams<any>();

  return (
    <BodyLayout>
      <Flex marginTop={5} marginBottom={5}>
        <Stack>
          <BaseBread itens={itens} />
        </Stack>
      </Flex>
      <Box
        rounded={"lg"}
        boxShadow={"lg"}
        p={10}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={6}>
          <FormAlterarNoticias id={id} />
        </Stack>
      </Box>
    </BodyLayout>
  );
};
