import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormControl,
  Input,
  Stack,
  chakra,
  IconButton,
  Tooltip,
  Flex,
  Text,
  Spacer,
  InputGroup,
  InputLeftElement,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import {
  useTable,
  useFilters,
  Cell,
  useSortBy,
  usePagination,
} from "react-table";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  MdEdit,
  MdArrowForward,
  MdArrowBack,
  MdSearch,
  MdDelete,
} from "react-icons/md";

import { TableSkeleton } from "./Skeleton/TableSkeleton";
import { api } from "../services/api";
import context from "../contexts/auth";
import { AxiosError, AxiosResponse } from "axios";
import { useHistory } from "react-router";

export const TableVideos: React.FC<{}> = () => {
  const [filterResp, setFilterResp] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [dataList, setDataList] = React.useState([]);
  const { refresh, logout } = React.useContext(context);
  const [uriDelete, setUriDelete] = React.useState<string>("");
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef<any>();

  let toast = useToast();

  const history = useHistory();

  React.useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 100);
  }, []);

  const fetchData = async () => {
    await api
      .get("videos/getall")
      .then((res: AxiosResponse) => {
        console.log(res.data)
        setDataList(res.data);
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          setDataList([]);
        } else if (error.response?.status === 401) {
          refresh().catch((error: AxiosError) => {
            if (error.response?.status === 401) {
              logout();
            }
          });
        }

        setLoading(false);
      });
  };

  const handleAlter = (id: number) => {
    history.replace(`/admin/videos/alterar/${id}`);
  };

  const handleDelete = (id: number) => {
    setIsOpen(true);
    setUriDelete(`videos/delete/${id}`);
  };

  const columns: any = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Título",
        accessor: "titulo",
      },
      {
        Header: "Data",
        accessor: "data",
      },
      {
        Header: "Ação",
        accessor: "action",
        Cell: (props: Cell) => {
          const row = props.row.values;

          return (
            <Stack direction={"row"} spacing={6}>
              <Tooltip label="Alterar">
                <IconButton
                  size="lg"
                  variant="ghost"
                  aria-label="Alterar"
                  icon={<MdEdit />}
                  onClick={() => handleAlter(row.id)}
                />
              </Tooltip>
              <Tooltip label="Deletar">
                <IconButton
                  size="lg"
                  variant="ghost"
                  aria-label="Deletar"
                  icon={<MdDelete />}
                  onClick={() => handleDelete(row.id)}
                />
              </Tooltip>
            </Stack>
          );
        },
      },
    ],
    []
  );

  const data = React.useMemo(() => dataList, [dataList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleFilterResp = (e: any) => {
    const value = e.target.value || "";
    setFilter("user", value);
    setFilterResp(value);
  };

  return (
    <Stack overflowX="auto">
      <TableSkeleton hidden={!loading} />
      <Stack hidden={loading}>
        <Stack direction="row-reverse">
          <Stack direction="column" w="500px">
            <FormControl>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<MdSearch />}
                  color="gray.600"
                />
                <Input
                  placeholder="Pesquisar"
                  value={filterResp}
                  onChange={handleFilterResp}
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </Stack>
        <Table variant="striped" {...getTableProps()} size="md">
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    isNumeric={column.isNumeric}
                  >
                    {column.render("Header")}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="Ordenar por decrescente" />
                        ) : (
                          <TriangleUpIcon aria-label="Ordenar por crescente" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
            <Tr hidden={!!rows.length}>
              <Td colSpan={visibleColumns.length} textAlign={"center"}>
                Lista de videos está vazia.
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <Flex>
          <Stack direction="row">
            <IconButton
              size="lg"
              variant="ghost"
              aria-label=""
              icon={<MdArrowBack />}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <IconButton
              size="lg"
              variant="ghost"
              aria-label=""
              icon={<MdArrowForward />}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
          </Stack>
          <Spacer />
          <Stack direction="row" marginTop={4}>
            <Text>
              Página {pageIndex + 1} de {pageOptions.length}
            </Text>
          </Stack>
        </Flex>
      </Stack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar
            </AlertDialogHeader>

            <AlertDialogBody>
              Realmente deseja deletar o video?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  await api
                    .delete(uriDelete)
                    .then(() => {
                      setIsOpen(false);
                      toast({
                        title: "Informação.",
                        description: "Video deletado.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      });
                      setLoading(true);
                      fetchData();
                    })
                    .catch((error: AxiosError) => {
                      refresh().catch((error: AxiosError) => {
                        if (error.response?.status === 401) {
                          logout();
                        }
                      });
                      toast({
                        title: "Informação.",
                        description: "Não foi possivel deletar o video.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    });
                }}
                ml={3}
              >
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
};
