import React from "react";
import { Stack, Skeleton, SkeletonCircle } from "@chakra-ui/react";

interface Props {
  hidden: boolean;
}

export const TableSkeleton: React.FC<Props> = (props: Props) => {
  return (
    <Stack hidden={props.hidden}>
      <SkeletonCircle size="10" />
      <Skeleton height="50px" />
      <Skeleton height="60px" />
      <Skeleton height="60px" />
      <Skeleton height="60px" />
      <Skeleton height="60px" />
      <Skeleton height="60px" />
      <Skeleton height="60px" />
      <Skeleton height="60px" />
    </Stack>
  );
};
