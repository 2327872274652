import React from "react";
import {
  FormControl,
  Stack,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { MdCheck } from "react-icons/md";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import context from "../../../contexts/auth";

interface VideoForm {
  title: string;
  video: string;
}

const validationSchema = Yup.object({
  title: Yup.string().required("Título não pode ser vazio.").max(300, "Tamanho máximo do texto é de 300 caracteres."),
  video: Yup.string().required("Video não pode ser vazia.").max(300, "Tamanho máximo do texto é de 300 caracteres."),
});

export const FormCadastrarVideos: React.FC<{}> = () => {
  const { refresh, logout } = React.useContext(context);
  const initialValues: VideoForm = {
    title: "",
    video: "",
  };
  const [submit, setSubmit] = React.useState<boolean>(false);
  const toast = useToast();

  return (
    <Stack spacing={4}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setSubmit(true);
          api
            .post("videos/save", {
              title: values.title,
              video: values.video,
            })
            .then(() => {
              toast({
                title: "Informação.",
                description: "Video cadastrado com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });

              setSubmit(false);
              resetForm();
            })
            .catch((error: AxiosError) => {
              if (error.response?.status === 401) {
                refresh().catch((error: AxiosError) => {
                  if (error.response?.status === 401) {
                    logout();
                  }
                });
              } else if (
                error.response?.status === 500 ||
                error.response?.status === 400
              ) {
                toast({
                  title: "Informação.",
                  description:
                    "Ops! Ocorreu um error, por favor entre em contato com o administrador.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });

                setSubmit(false);
              }
            });
        }}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Field name="title">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.title && touched.title}
                    isRequired
                  >
                    <FormLabel htmlFor="title">Título:</FormLabel>
                    <Input
                      {...field}
                      id="title"
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.title && touched.title && errors.title}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="video">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.video && touched.video}
                    isRequired
                  >
                    <FormLabel htmlFor="video">Video:</FormLabel>
                    <Input
                      {...field}
                      id="video"
                      type="text"
                      value={values.video}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.video && touched.video && errors.video}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Stack spacing={10} width="150px">
                <Button
                  isLoading={submit}
                  loadingText={"Publicando..."}
                  type="submit"
                  leftIcon={<MdCheck />}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  Publicar
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
