import React from "react";
import {
  FormControl,
  Stack,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  useToast,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { MdCheck } from "react-icons/md";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import context from "../../../contexts/auth";

interface EventoForm {
  description: string;
  title: string;
  start: string;
  end: string;
}

const validationSchema = Yup.object({
  title: Yup.string().required("Título não pode ser vazio.").max(500, "Tamanho máximo do texto é de 500 caracteres."),
  description: Yup.string().required("Descrição não pode ser vazia.").max(300, "Tamanho máximo do texto é de 300 caracteres."),
  start: Yup.string().required("Data de Inicio não pode ser vazia."),
  end: Yup.string().required("Data de Fim não pode ser vazia."),
});

export const FormCadastrarEventos: React.FC<{}> = () => {
  const { refresh, logout } = React.useContext(context);
  const initialValues: EventoForm = {
    description: "",
    title: "",
    start: "",
    end: "",
  };
  const [submit, setSubmit] = React.useState<boolean>(false);
  const toast = useToast();

  return (
    <Stack spacing={4}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setSubmit(true);
          api
            .post("events/save", {
              title: values.title,
              description: values.description,
              start: values.start,
              end: values.end,
            })
            .then(() => {
              toast({
                title: "Informação.",
                description: "Evento Cadastrado com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });

              setSubmit(false);
              resetForm();
            })
            .catch((error: AxiosError) => {
              if (error.response?.status === 401) {
                refresh().catch((error: AxiosError) => {
                  if (error.response?.status === 401) {
                    logout();
                  }
                });
              } else if (
                error.response?.status === 500 ||
                error.response?.status === 400
              ) {
                toast({
                  title: "Informação.",
                  description:
                    "Ops! Ocorreu um error, por favor entre em contato com o administrador.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            });
        }}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Field name="title">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.title && touched.title}
                    isRequired
                  >
                    <FormLabel htmlFor="title">Título:</FormLabel>
                    <Input
                      {...field}
                      id="title"
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.title && touched.title && errors.title}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.description && touched.description}
                    isRequired
                  >
                    <FormLabel htmlFor="description">Descrição:</FormLabel>
                    <Textarea
                      {...field}
                      id="description"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <SimpleGrid columns={2} spacing={10}>
                <Field name="start">
                  {(field: any) => (
                    <FormControl
                      isInvalid={!!errors.start && touched.start}
                      isRequired
                    >
                      <FormLabel htmlFor="start">Data de Inicio:</FormLabel>
                      <Input
                        {...field}
                        id="start"
                        type="date"
                        value={values.start}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>
                        {errors.start && touched.start && errors.start}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="end">
                  {(field: any) => (
                    <FormControl
                      isInvalid={!!errors.end && touched.end}
                      isRequired
                    >
                      <FormLabel htmlFor="end">Data de Fim:</FormLabel>
                      <Input
                        {...field}
                        id="end"
                        type="date"
                        value={values.end}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>
                        {errors.end && touched.end && errors.end}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>
              <Stack spacing={10} width="150px">
                <Button
                  isLoading={submit}
                  loadingText={"Cadastrando..."}
                  type="submit"
                  leftIcon={<MdCheck />}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  Cadastrar
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
