import React from "react";
import { Text } from "@chakra-ui/react";

export const Crendenciais: React.FC = () => {
  const data = new Date();
  return (
    <Text
      marginTop={10}
      fontSize={"sm"}
      textAlign={"center"}
    >{`DTI - Departamento de Tecnologia de Tacaratu © ${data.getFullYear()}`}</Text>
  );
};
