import React from "react";
import { Route, BrowserRouter, Redirect } from "react-router-dom";
import { Auth } from "../pages/auth";

const AuthRoutes: React.FC = () => {
  return (
    <BrowserRouter>
        <Route exact path="/" component={Auth} />
        <Route path="/*">
          <Redirect to="/" />
        </Route>
    </BrowserRouter>
  );
};

export default AuthRoutes;
