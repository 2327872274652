import { AxiosResponse, AxiosError } from "axios";
import React, { createContext } from "react";
import { api } from "../services/api";
import context from "./auth";

interface SlideContextData {
  dataList: Array<object>;
  loading: boolean;
  fetchData(): Promise<void>;
  reloadData(): void;
  handleLoading(value:boolean):void;
}

const slides = createContext<SlideContextData>({} as SlideContextData);

export const SlideProvider: React.FC = ({ children }) => {
  const [dataList, setDataList] = React.useState<Array<object>>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { refresh, logout } = React.useContext(context);

  const fetchData = async () => {
    await api
      .get("slides/getall")
      .then((res: AxiosResponse) => {
        setDataList(res.data);
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          setDataList([]);
        } else if (error.response?.status === 401) {
          refresh().catch((error: AxiosError) => {
            if (error.response?.status === 401) {
              logout();
            }
          });
        }

        setLoading(false);
      });
  };

  const reloadData = () => {
    fetchData();
  };

  const handleLoading = (value:boolean) => {
    setLoading(value)
  };

  return (
    <slides.Provider value={{ dataList, loading, fetchData, reloadData, handleLoading }}>
      {children}
    </slides.Provider>
  );
};

export default slides;
