import React from "react";
import {
  Box,
  Stack,
  Button,
  useColorModeValue,
  Flex,
  Spacer,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { MdFileUpload } from "react-icons/md";

import BodyLayout from "../../components/BodyLayout";
import { MdPublish } from "react-icons/md";
import { BaseBread } from "../../components/Breadcrumb/BaseBread";
import { TableSlides } from "../../components/TableSlides";
import { api } from "../../services/api";
import { AxiosResponse, AxiosError } from "axios";
import context from "../../contexts/auth";
import slides from "../../contexts/slides";

const itens = [
  {
    link: "/admin/eventos",
    title: "Eventos",
    isCurrent: true,
  },
  {
    link: "#",
    title: "Slides",
    isCurrent: true,
  },
];

interface SlidesForm {
  photo: string;
}

const validationSchema = Yup.object({
  photo: Yup.mixed()
    .required("Imagem não pode ser vazio.")
    .test(
      "aspectRatio",
      "Resolução inválida, por favor escolha uma imagem com a resolução 1920x400.",
      (value: Blob) => {
        return new Promise((resolve) => {
          if (value != null) {
            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onload = (value) => {
              const img: HTMLImageElement = new Image();
              img.src = String(value.target?.result);
              img.onload = function () {
                const aspectRatio = img.width / img.height;
                resolve(aspectRatio === 24 / 5);
              };
            };
          }
        });
      }
    ),
});

export const Slides: React.FC<{}> = () => {
  const { refresh, logout } = React.useContext(context);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialValues: SlidesForm = {
    photo: "",
  };
  const [loading, setLoading] = React.useState<boolean>(false);
  const { reloadData } = React.useContext(slides);
  const toast = useToast();

  const postSlides = async (data: FormData) => {
    await api
      .post("slides/save", data)
      .then((res: AxiosResponse) => {
        toast({
          title: "Informação.",
          description: "Imagem publicada com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setLoading(false);
        reloadData();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          refresh().catch((error: AxiosError) => {
            if (error.response?.status === 401) {
              logout();
            }
          });
        } else if (
          error.response?.status === 500 ||
          error.response?.status === 400
        ) {
          toast({
            title: "Informação.",
            description:
              "Ops! Ocorreu um error, por favor entre em contato com o administrador.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        setLoading(false);
      });
  };

  return (
    <BodyLayout>
      <Flex marginTop={5} marginBottom={5}>
        <Stack>
          <BaseBread itens={itens} />
        </Stack>
        <Spacer />
        <Stack>
          <Button
            leftIcon={<MdPublish />}
            colorScheme="blue"
            variant="solid"
            onClick={onOpen}
          >
            Publicar uma imagem
          </Button>
        </Stack>
      </Flex>
      <Box
        rounded={"lg"}
        boxShadow={"lg"}
        p={10}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={6}>
          <TableSlides />
        </Stack>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Publicar imagem.</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}></ModalBody>
          <Stack spacing={4}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                let data = new FormData();

                data.append("photo", values.photo);

                setLoading(true);
                await postSlides(data);
                onClose();
                resetForm();
              }}
            >
              {({ handleSubmit, handleBlur, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <Stack spacing={4} margin={2}>
                    <Field name="photo">
                      {(field: any) => (
                        <FormControl
                          isInvalid={!!errors.photo && touched.photo}
                          isRequired
                        >
                          <FormLabel htmlFor="photo">Imagem:</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<MdFileUpload />}
                            />
                            <Input
                              {...field}
                              id="photo"
                              type="file"
                              name="photo"
                              multiple
                              onChange={(event: any) => {
                                field.form.setFieldValue(
                                  "photo",
                                  event.currentTarget.files[0]
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {errors.photo && touched.photo && errors.photo}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <ModalFooter>
                    <Button
                      type="submit"
                      leftIcon={<MdPublish />}
                      bg={"blue.400"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                      spinnerPlacement="start"
                      isLoading={loading}
                      loadingText="Publicando..."
                      mr={3}
                    >
                      Publicar
                    </Button>
                    <Button onClick={onClose}>Cancelar</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </Stack>
        </ModalContent>
      </Modal>
    </BodyLayout>
  );
};
