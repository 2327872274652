import React, { createContext } from "react";
import { api } from "../services/api";
import { LoadingPage } from "../pages/loader";
import { useHistory } from "react-router";

export interface UserData {
  name: string;
  type: string;
}
interface AuthContextData {
  signed: boolean;
  user: UserData | null;
  auth(user: object): Promise<void>;
  logout(): void;
  refresh(): Promise<void>;
  loading: boolean;
  handleLoading(value: boolean): void;
}

const context = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  let history = useHistory();

  React.useEffect(() => {
    const loadStoragedData = async () => {
      const storagedUser = localStorage.getItem("user");
      const storagedToken = localStorage.getItem("token");

      if (storagedUser && storagedToken) {
        setUser(JSON.parse(storagedUser));
        api.defaults.headers["Authorization"] = `Bearer ${storagedToken}`;
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000)
    };

    loadStoragedData();
  }, []);

  const [user, setUser] = React.useState<any | null>();

  const auth = async (user: object) => {  
    const response = await api.post("auth/login", user);

    setUser(response.data.user);

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(response.data.user));

    api.defaults.headers["Authorization"] = `Bearer ${response.data.token}`;

    history.replace("/admin/eventos");
  };

  const refresh = async () => {
    const response = await api.post("auth/refresh");

    setUser(response.data.user);

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(response.data.user));

    api.defaults.headers["Authorization"] = `Bearer ${response.data.token}`;
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
  };

  const handleLoading = (value:boolean) => {
    setLoading(value);
  };

  if(loading) {
    return <LoadingPage />
  }

  return (
    <context.Provider
      value={{
        signed: !!user,
        user,
        loading,
        handleLoading,
        auth,
        refresh,
        logout,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default context;
