import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./contexts/auth";
import { SlideProvider } from "./contexts/slides";
import Routes from "./routes";
import theme from "./theme";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <SlideProvider>
          <Routes />
        </SlideProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
