import React from "react";
import {
  Box,
  Stack,
  Button,
  useColorModeValue,
  Flex,
  Spacer,
} from "@chakra-ui/react";

import BodyLayout from "../../components/BodyLayout";
import { TableEventos } from "../../components/TableEventos";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { BaseBread } from "../../components/Breadcrumb/BaseBread";

const itens = [
  {
    link: "#",
    title: "Eventos",
    isCurrent: true,
  },
];

export const Eventos: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <BodyLayout>
      <Flex marginTop={5} marginBottom={5}>
        <Stack>
          <BaseBread itens={itens} />
        </Stack>
        <Spacer />
        <Stack>
          <Button
            leftIcon={<MdAdd />}
            colorScheme="blue"
            variant="solid"
            onClick={() => history.replace("/admin/eventos/novo")}
          >
            Novo Evento
          </Button>
        </Stack>
      </Flex>
      <Box
        rounded={"lg"}
        boxShadow={"lg"}
        p={10}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={6}>
          <TableEventos />
        </Stack>
      </Box>
    </BodyLayout>
  );
};
