import React from "react";
import {
  FormControl,
  Stack,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  useToast,
  SimpleGrid,
  Textarea,
  Progress,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { MdCheck } from "react-icons/md";
import { api } from "../../../services/api";
import { AxiosError, AxiosResponse } from "axios";
import context from "../../../contexts/auth";
import { useHistory } from "react-router";

export interface EventoForm {
  description: string;
  title: string;
  start: string;
  end: string;
  id: number;
}

export interface Param {
  id: number;
}

const validationSchema = Yup.object({
  title: Yup.string().required("Título não pode ser vazio.").max(500, "Tamanho máximo do texto é de 500 caracteres."),
  description: Yup.string().required("Descrição não pode ser vazia.").max(300, "Tamanho máximo do texto é de 300 caracteres."),
  start: Yup.string().required("Data de Inicio não pode ser vazia."),
  end: Yup.string().required("Data de Fim não pode ser vazia."),
});

export const FormAlterarEventos: React.FC<Param> = (props: Param) => {
  const { refresh, logout } = React.useContext(context);
  const [initialValues, setInitialValues] = React.useState<EventoForm>({
    description: "",
    title: "",
    start: "",
    end: "",
    id: 0,
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [submint, setSubmit] = React.useState<boolean>(false);

  const toast = useToast();
  let history = useHistory();

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await api
      .get(`events/detail/${props.id}`)
      .then((res: AxiosResponse) => {
        setInitialValues({
          description: res.data[0].description,
          title: res.data[0].title,
          start: res.data[0].start,
          end: res.data[0].end,
          id: res.data[0].id,
        });
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        refresh().catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            logout();
          }
        });
      });
  };

  return (
    <Stack spacing={4}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setSubmit(true);
          api
            .put("events/update", {
              title: values.title,
              description: values.description,
              start: values.start,
              end: values.end,
              event: initialValues.id,
            })
            .then(() => {
              toast({
                title: "Informação.",
                description: "Evento alterado com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });

              resetForm();
              setSubmit(false);
              history.replace("/admin/eventos");
            })
            .catch((error: AxiosError) => {
              if (error.response?.status === 401) {
                refresh().catch((error: AxiosError) => {
                  if (error.response?.status === 401) {
                    logout();
                  }
                });
              } else if (
                error.response?.status === 500 ||
                error.response?.status === 400 ||
                error.response?.status === 404
              ) {
                toast({
                  title: "Informação.",
                  description:
                    "Ops! Ocorreu um error, por favor entre em contato com o administrador.",
                  status: "error",
                  duration: 8000,
                  isClosable: true,
                });
              }
            });
        }}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Progress size="xs" isIndeterminate={loading} hidden={!loading} />
              <Field name="title">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.title && touched.title}
                    isRequired
                  >
                    <FormLabel htmlFor="title">Título:</FormLabel>
                    <Input
                      {...field}
                      id="title"
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.title && touched.title && errors.title}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {(field: any) => (
                  <FormControl
                    isInvalid={!!errors.description && touched.description}
                    isRequired
                  >
                    <FormLabel htmlFor="description">Descrição:</FormLabel>
                    <Textarea
                      {...field}
                      id="description"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <SimpleGrid columns={2} spacing={10}>
                <Field name="start">
                  {(field: any) => (
                    <FormControl
                      isInvalid={!!errors.start && touched.start}
                      isRequired
                    >
                      <FormLabel htmlFor="start">Data de Inicio:</FormLabel>
                      <Input
                        {...field}
                        id="start"
                        type="date"
                        value={values.start}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>
                        {errors.start && touched.start && errors.start}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="end">
                  {(field: any) => (
                    <FormControl
                      isInvalid={!!errors.end && touched.end}
                      isRequired
                    >
                      <FormLabel htmlFor="end">Data de Fim:</FormLabel>
                      <Input
                        {...field}
                        id="end"
                        type="date"
                        value={values.end}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>
                        {errors.end && touched.end && errors.end}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>
              <Stack spacing={10} width="150px">
                <Button
                  type="submit"
                  isLoading={submint}
                  loadingText={"Alterando..."}
                  leftIcon={<MdCheck />}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  disabled={loading}
                >
                  Alterar
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
