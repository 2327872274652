import axios from "axios";

let baseUrl = process.env.REACT_APP_HOST_DEV+"api/";
if (process.env.NODE_ENV !== "development") {
  baseUrl = process.env.REACT_APP_HOST_PROD+"api/";
}

export const api = axios.create({
  baseURL: baseUrl,
});
