import React from "react";
import {
  Box,
  Stack,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";

import BodyLayout from "../../components/BodyLayout";
import { BaseBread } from "../../components/Breadcrumb/BaseBread";
import { FormCadastrarEventos } from "../../components/Forms/Eventos/FormCadastrar";

const itens = [
  {
    link: "/admin/eventos",
    title: "Eventos",
    isCurrent: true,
  },
  {
    link: "#",
    title: "Novo Evento",
    isCurrent: true,
  },
];

export const CadastrarEventos: React.FC<{}> = () => {

  return (
    <BodyLayout>
      <Flex marginTop={5} marginBottom={5}>
        <Stack>
          <BaseBread itens={itens} />
        </Stack>
      </Flex>
      <Box
        rounded={"lg"}
        boxShadow={"lg"}
        p={10}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={6}>
          <FormCadastrarEventos />
        </Stack>
      </Box>
    </BodyLayout>
  );
};
